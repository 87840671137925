import { useState, useEffect } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import Layout from "../components/common/Layout";
import { ReactComponent as UserStar } from "../assets/icons/UserStar.svg";
import { ReactComponent as XCircle } from "../assets/icons/XCircle.svg";
import { ReactComponent as MailTime } from "../assets/icons/MailTime.svg";
import { ReactComponent as MailRoundArrow } from "../assets/icons/MailRoundArrow.svg";
import { getAllDashboardStatsAPI } from "../services/apis/Dashboard";

// Register the necessary chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export default function Dashboard() {
  const orgId = JSON.parse(localStorage.getItem("user"))["organization"];

  const [stats, setStats] = useState([]);
  const [sessions, setSessions] = useState(null);

  useEffect(() => {
    get_all_stats();
  }, []);

  async function get_all_stats() {
    try {
      const res = await getAllDashboardStatsAPI(orgId);
      const data = res.data.result[0];

      // Transform stats for display in Doughnut and summary
      const customRes = [
        {
          icon: MailRoundArrow,
          total: data.total_request,
          unit: "Requests",
          label: "Number of requests",
          color: "#5570F1",
        },
        {
          icon: MailTime,
          total: data.latency.toFixed(2),
          unit: "Seconds",
          label: "Response time",
          color: "#97A5EB",
        },
        {
          icon: XCircle,
          total: data.total_failed_request,
          unit: "Requests",
          label: "Failed Requests",
          color: "#EF3826",
        },
        {
          icon: UserStar,
          total: data.users,
          unit: "User",
          label: "Unique users by IP",
          color: "#00B69B",
        },
      ];
      setStats(customRes);

      // Transform sessions data for the Bar chart
      const transformedSessions = {
        labels: data.sessions.map((entry) => entry.Date),
        datasets: [
          {
            label: "frequency",
            data: data.sessions.map((entry) => entry.frequency),
            backgroundColor: "rgba(85, 112, 241, 0.5)",
            borderColor: "rgba(85, 112, 241, 1)",
            borderWidth: 1,
          },
        ],
      };
      setSessions(transformedSessions);
    } catch (error) {
      console.log("Error fetching stats:", error);
    }
  }

  return (
    <Layout header="Dashboard">
      {/* CHARTS */}
      <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
        <h1>Data Filter</h1>

        <div className="flex mt-5">
          <div className="w-[40%]">
            <Doughnut
              data={{
                labels: stats.map((item) => item.label),
                datasets: [
                  {
                    data: stats.map((item) => item.total),
                    backgroundColor: stats.map((item) => item.color),
                    borderColor: "transparent",
                    borderWidth: 0,
                    hoverOffset: 4,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                cutout: "70%",
                plugins: {
                  legend: {
                    labels: {
                      boxWidth: 12,
                      padding: 20,
                      usePointStyle: true,
                      pointStyle: "circle",
                    },
                  },
                },
              }}
              width={300}
              height={300}
            />
          </div>

          <div className="grow grid grid-cols-4 gap-4">
            {stats.map((item) => (
              <div className="flex flex-col h-full" key={item.label}>
                <div
                  style={{ backgroundColor: item.color }}
                  className="text-white rounded-xl p-5 mb-5 flex-grow flex flex-col justify-center text-center"
                >
                  <item.icon className="mb-auto mx-auto mt-5" />
                  <h1 className="font-bold text-4xl">{item.total}</h1>
                  <h4 className="font-thin">{item.unit}</h4>
                </div>
                <div
                  style={{ backgroundColor: item.color, opacity: "80%" }}
                  className="text-white rounded-xl p-2 text-center text-lg"
                >
                  {item.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <br />
      <div className="ring-1 ring-gray-200 sm:mx-0 rounded-lg bg-white p-5">
        <h1>Bar Chart</h1>
        <div className="max-w-2xl">
          {sessions && (
            <Bar
              data={sessions}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: "top" },
                  title: { display: true, text: "Session Frequency" },
                },
                scales: { y: { beginAtZero: true } },
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}
