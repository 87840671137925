export const API_GET_ALL_LOGS = {
  url: "/api/v1/logs/organization",
  method: "GET",
};

export const API_GET_ALL_REPORTS = {
  url: "/api/v1/logs/report/organization",
  method: "GET",
};

export const API_GET_ALL_DASHBOARD_STATS = {
  url: "/api/v1/logs/dashboard/organization",
  method: "GET",
};

export const API_GET_ALL_CONVERSATIONS = {
  url: "/api/v1/conversations/organization",
  method: "GET",
};
