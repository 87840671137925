import axios from "axios";
import { BASE_URL } from "../config";

export const API_LOGIN = {
  url: "/api/v1/admins/login",
  method: "POST",
};

export const API_GET_ALL_USERS = {
  url: "/api/v1/admins/organization",
  method: "GET",
};

export const API_CREATE_USER = {
  url: "/api/v1/admins",
  method: "POST",
};

export const API_REMOVE_USER = {
  url: "/api/v1/admins/delete",
  method: "DELETE",
};

export const API_UPDATE_USER = {
  url: "/api/v1/organizations",
  method: "PUT",
};

export const API_GET_SPECIFIC_USERS = {
  url: "/api/v1/organizations/read",
  method: "GET",
};


export const modifyPassword = async (body) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/admins/changePassword`, body, {
      headers: { 'Content-Type': 'application/json' }
    })
    console.log('response', response)
    if (response.status === 200) {
      return response.data.data
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}

export const updateUserPassword = async (body, id) => {
  try {
    const response = await axios.put(`${BASE_URL}/api/v1/admins/updatePassword/${id}`, body, {
      headers: { 'Content-Type': 'application/json' }
    })
    console.log('response', response)
    if (response.status === 200) {
      return response.data.data
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}


export const create = async (body) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/admins`, body, {
      headers: { 'Content-Type': 'application/json' }
    })
    console.log('response', response)
    if (response.status === 200) {
      return response.data.data
    }
    return response
  } catch (error) {
    console.log('error', error)
    throw (error.response.data)
  }
}