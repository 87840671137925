import instance from "../../utils/publicInstance";
import {
  API_GET_ALL_LOGS,
  API_GET_ALL_DASHBOARD_STATS,
  API_GET_ALL_REPORTS,
} from "../../constants/apis/Dashboard";

const getAllLogsAPI = async (orgId, params) => {
  return await instance({
    ...API_GET_ALL_LOGS,
    url: `${API_GET_ALL_LOGS.url}/${orgId}`,
    params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

const getAllDashboardStatsAPI = async (orgId, params) => {
  return await instance({
    ...API_GET_ALL_DASHBOARD_STATS,
    url: `${API_GET_ALL_DASHBOARD_STATS.url}/${orgId}`,
    params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

const getAllReportsAPI = async (orgId, params) => {
  return await instance({
    ...API_GET_ALL_REPORTS,
    url: `${API_GET_ALL_REPORTS.url}/${orgId}`,
    params,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

export { getAllLogsAPI, getAllDashboardStatsAPI, getAllReportsAPI };
