import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/common/Layout";
import { ReactComponent as Upload } from "../assets/icons/Upload.svg";
import { ReactComponent as Trash } from "../assets/icons/Trash.svg";
// import { ReactComponent as Edit } from "../assets/icons/Edit.svg";
import ConfirmationModal from "../components/common/ConfirmationModal";

export default function DataManager() {
  const [uploadStatus, setUploadStatus] = useState("");
  const [data, setdata] = useState([]);
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);
  const [selectedFile, setselectedFile] = useState("");

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setUploadStatus("No file selected. Please choose a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setUploadStatus("Uploading...");
      const response = await axios.post(
        "https://qiwa-api.labeah.ai/logs/upload/file?lang=ar", // Adjust "lang" query parameter as needed
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUploadStatus("File uploaded successfully!");
      console.log("Upload response:", response.data);
      get_all_uploaded_files();
    } catch (error) {
      setUploadStatus("File upload failed. Please try again.");
      console.error("Upload error:", error);
    }
  };

  async function get_all_uploaded_files() {
    const response = await axios.get("https://qiwa-api.labeah.ai/logs/files");
    setdata(response.data.files);
  }

  async function remove_file() {
    try {
      const response = await axios.delete(
        "https://qiwa-api.labeah.ai/logs/delete/file",
        {
          data: {
            lang: "ar",
            file_name: selectedFile,
          },
        }
      );
      console.log(response.data); // Log the response data
      setisDeleteModalOpen(false);
      get_all_uploaded_files();
    } catch (error) {
      console.error("Error removing file:", error);
    }
  }

  useEffect(() => {
    get_all_uploaded_files();
  }, []);

  return (
    <Layout header="Data Manager">
      <div className="p-6">
        {/* UPLOADER */}
        <div className="bg-white rounded-2xl flex justify-center items-center py-9 border border-[#E1E2E9]">
          <div className="text-center max-w-[13rem] mx-auto space-y-3">
            <Upload className="mx-auto" />
            <h3 className="text-primary text-lg">Data Uploader</h3>
            <p className="text-sm text-[#8B8D97] pb-4">
              Upload files from data files for your product.
              <br /> File Format <span className="text-[#2C2D33]">Word</span>
            </p>
            <label className="bg-primary text-white rounded-xl px-7 py-2 cursor-pointer">
              Upload
              <input
                type="file"
                className="hidden"
                accept=".doc,.docx"
                onChange={handleFileChange}
              />
            </label>
            {uploadStatus && <p className="text-sm mt-2">{uploadStatus}</p>}
          </div>
        </div>

        <br />
        {/* TABLE */}
        <div>
          <h1 className="text-2xl mb-3">My Files</h1>
          <Table
            data={data}
            setisDeleteModalOpen={setisDeleteModalOpen}
            setselectedFile={setselectedFile}
          />
        </div>
      </div>
      <ConfirmationModal
        title="file"
        open={isDeleteModalOpen}
        setOpen={setisDeleteModalOpen}
        func={() => remove_file()}
      />
    </Layout>
  );
}

function Table({ data, setisDeleteModalOpen, setselectedFile }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg bg-white">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              ID
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Name
            </th>
            {/* <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              File Size
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Timestamp
            </th> */}

            <th
              scope="col"
              className=" text-right	 px-12 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((data, dataIdx) => (
            <tr key={data.id}>
              <td
                className={classNames(
                  dataIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-6 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {dataIdx + 1}
              </td>

              <td
                className={classNames(
                  dataIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {data}
              </td>
              {/* <td
                className={classNames(
                  dataIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {data.fileSize}
              </td>
              <td
                className={classNames(
                  dataIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
              >
                {data.timestamp}
              </td> */}

              <td
                className={classNames(
                  dataIdx === 0 ? "" : "border-t border-gray-200",
                  "relative py-3.5 px-12  text-right text-sm font-medium lg:table-cell"
                )}
              >
                <div className="inline-flex w-fit border border-[#D5D5D5] rounded-lg bg-[#FAFBFD]">
                  {/* <button
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5  border-r border-[#D5D5D5]"
                  >
                    <Edit />
                  </button> */}

                  <button
                    type="button"
                    onClick={() => {
                      setisDeleteModalOpen(true);
                      setselectedFile(data);
                    }}
                    className="inline-flex items-center px-2.5 py-1.5  "
                  >
                    <Trash />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
