import instance from "../../utils/publicInstance";
import {
  API_LOGIN,
  API_CREATE_USER,
  API_GET_ALL_USERS,
  API_REMOVE_USER,
  API_UPDATE_USER,
  API_GET_SPECIFIC_USERS,
} from "../../constants/apis/Auth";

const loginAPI = async (data) => {
  const config = {
    ...API_LOGIN,
    data,
  };
  return await instance(config)
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

const getAllUsersAPI = async (orgId) => {
  return await instance({
    ...API_GET_ALL_USERS,
    url: `${API_GET_ALL_USERS.url}/${orgId}`,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

const createUserAPI = async (data) => {
  const config = {
    ...API_CREATE_USER,
    data,
  };
  return await instance(config)
    .then((res) => {
      return res;
    })
    .catch(((e) => console.log(e)));
};

const removeUserAPI = async (id) => {
  const config = {
    ...API_REMOVE_USER,
    url: `${API_REMOVE_USER.url}/${id}`,
  };
  return await instance(config)
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

const updateUserAPI = async (orgId, data) => {
  const config = {
    ...API_UPDATE_USER,
    url: `${API_UPDATE_USER.url}/${orgId}`,
    data,
  };
  return await instance(config)
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

const getSpecificUsersAPI = async (orgId) => {
  return await instance({
    ...API_GET_SPECIFIC_USERS,
    url: `${API_GET_SPECIFIC_USERS.url}/${orgId}`,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => console.log(e));
};

export {
  loginAPI,
  getAllUsersAPI,
  createUserAPI,
  removeUserAPI,
  updateUserAPI,
  getSpecificUsersAPI,
};
