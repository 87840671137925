export const API_LOGIN = {
  url: "/api/v1/admins/login",
  method: "POST",
};

export const API_GET_ALL_USERS = {
  url: "/api/v1/admins/organization",
  method: "GET",
};

export const API_CREATE_USER = {
  url: "/api/v1/admins",
  method: "POST",
};

export const API_REMOVE_USER = {
  url: "/api/v1/admins/delete",
  method: "DELETE",
};

export const API_UPDATE_USER = {
  url: "/api/v1/organizations",
  method: "PUT",
};

export const API_GET_SPECIFIC_USERS = {
  url: "/api/v1/organizations/read",
  method: "GET",
};
