import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import { getAllConversationsAPI } from "../services/apis/Dashboard";
import {
  ChevronRightIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";

export default function Conversations() {
  const orgId = JSON.parse(localStorage.getItem("user"))["organization"];
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const [filter, setFilter] = useState({ page: 1, limit: 6 });

  async function get_all_conversations() {
    await getAllConversationsAPI(orgId, filter)
      .then((res) => {
        setSelectedItem(res.data.data[0]);
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    get_all_conversations();
  }, [filter]);

  const totalPages = Math.ceil(total / filter.limit);

  const handleNextPage = () => {
    if (filter.page < totalPages) {
      setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (filter.page > 1) {
      setFilter((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  return (
    <Layout header="Conversations">
      <div className="flex h-[90vh] p-6 space-x-5">
        <div className="w-[30rem] bg-white ring-1 ring-gray-200 p-5 rounded-lg">
          <h1 className="font-medium">All Conversations</h1>
          <div className="overflow-y-auto h-[70vh]">
            {data.map((item, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => setSelectedItem(item)}
                  className={`bg-gray-50 my-3 p-3 rounded-lg cursor-pointer ${
                    selectedItem._id === item._id
                      ? "border border-primary"
                      : "border"
                  }`}
                >
                  <div className="flex justify-between">
                    <div className="flex space-x-3">
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"
                        className="h-10 w-10 rounded-full m-auto"
                      />
                      <div>
                        <h1 className="font-bold">{item.ip_address}</h1>
                        <h1>{item.country}</h1>
                        <p className="flex text-sm">
                          <span className="font-bold mr-1">Created at </span>
                          {moment(item.created_on).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                    <ChevronRightIcon className="h-5 w-5 my-auto" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrevPage}
              disabled={filter.page === 1}
              className={`relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                filter.page === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Previous
            </button>
            <span className="px-4 py-2">
              Page {filter.page} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={filter.page === totalPages}
              className={`relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                filter.page === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Next
            </button>
          </div>
        </div>

        {/* Conversations */}
        <div className="w-full bg-white ring-1 ring-gray-200 p-5 rounded-lg">
          <div className="overflow-auto">
            {selectedItem?.conversation?.length > 0 ? (
              selectedItem.conversation.map((item, index) => (
                <div
                  key={index}
                  className={`py-2 ${
                    item.by === "user" ? "bg-gray-100" : "bg-white"
                  }`}
                >
                  <div className="flex max-w-5xl mx-auto">
                    <img
                      src={
                        item.by === "llm"
                          ? "https://marketplace.mypurecloud.com/add4eee6-9406-448c-9ff7-6d1065338f2a/applogo_f4652212.jpg"
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                      }
                      className="h-12 w-12 rounded-lg my-auto mx-2 object-cover"
                    />
                    <div className="mx-1 w-full p-3 rounded-lg">
                      {item.text}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-400 py-10">
                No chats yet. Start a conversation!
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
