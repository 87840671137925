import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Logo } from "../assets/icons/Logo.svg";
import { ReactComponent as Email } from "../assets/icons/Email.svg";
import { ReactComponent as Lock } from "../assets/icons/Lock.svg";

import { loginAPI } from "../services/apis/Auth";

export default function Login() {
  const navigate = useNavigate();
  const [user, setuser] = useState({
    email: "", //qiwa@labeah.com
    password: "", //123123
  });

  async function login() {
    await loginAPI(user)
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.data));
        navigate("/dashboard");
      })
      .catch((err) =>
        toast.error("Incorrect credentials.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        })
      );
  }

  return (
    <div className="h-screen flex justify-center items-center bg-login-bg bg-cover bg-bottom">
      <div className="bg-white rounded-2xl w-[30rem] py-10 px-5">
        {/* <Logo className="mx-auto" /> */}
        <h1 className="text-center mt-5 text-lg font-medium mb-1">
          Welcome Back!
        </h1>
        <h4 className="text-center text-[#8B8D97] mb-3">
          Login to your account
        </h4>

        <div className="space-y-4 pt-7 pb-5">
          <div className="flex bg-[#EFF1F999] rounded-xl">
            <Email className="my-auto mx-3" />
            <input
              className="bg-[#EFF1F999] w-full py-3 rounded-r-xl outline-none"
              placeholder="Email"
              type="email"
              onChange={(e) => setuser({ ...user, email: e.target.value })}
            />
          </div>

          <div className="flex bg-[#EFF1F999] rounded-xl">
            <Lock className="my-auto mx-3" />
            <input
              className="bg-[#EFF1F999] w-full py-3 rounded-r-xl outline-none"
              placeholder="Password"
              type="password"
              onChange={(e) => setuser({ ...user, password: e.target.value })}
            />
          </div>
        </div>
        <button
          onClick={login}
          className="flex bg-primary mx-auto text-white rounded-xl px-7 py-2 mt-7"
        >
          Login
        </button>
      </div>

      <ToastContainer />
    </div>
  );
}
