import React from "react";
import Layout from "../components/common/Layout";

const stats = [
  {
    name: "Consumption",
    stat: "71,897",
    previousStat: "70,946",
  },
];

export default function Billing() {
  return (
    <Layout header="Billing">
      <div className="p-6">
        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-1 md:divide-x md:divide-y-0 max-w-sm">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                {item.name}
              </dt>
              <dd className="mt-3 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-red-600">
                  {item.previousStat}
                  <span className="ml-2 text-xl font-medium text-gray-500">
                    / {item.stat}
                  </span>
                </div>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Layout>
  );
}
