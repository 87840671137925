import { useState, useEffect } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/common/Layout";
import ConfirmationModal from "../components/common/ConfirmationModal";
import { ReactComponent as Trash } from "../assets/icons/Trash.svg";
import { ReactComponent as Edit } from "../assets/icons/Edit.svg";
import { ReactComponent as User } from "../assets/icons/User.svg";
import { ReactComponent as Email } from "../assets/icons/Email.svg";
import { ReactComponent as Photo } from "../assets/icons/Photo.svg";
import {
  createUserAPI,
  getAllUsersAPI,
  removeUserAPI,
  updateUserAPI,
} from "../services/apis/Auth";

export default function Settings() {
  const orgId = JSON.parse(localStorage.getItem("user"))["organization"];
  const adminId = JSON.parse(localStorage.getItem("user"))["_id"];
  const [user, setuser] = useState({
    email: "",
    password: "",
    role: "",
  });
  const [account, setaccount] = useState({
    name: "",
    logo: "",
  });
  const [users, setusers] = useState([]);
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);
  const [selectedUserId, setselectedUserId] = useState("");

  const [selectedTab, setselectedTab] = useState("user");

  async function create_user() {
    await createUserAPI({ ...user, organization: orgId })
      .then((res) => {
        get_all_users();
        toast.success("User successfully created.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      })
      .catch((err) =>
        toast.error("Something went wrong, please try again.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        })
      );
  }

  async function get_all_users() {
    await getAllUsersAPI(orgId)
      .then((res) => {
        setusers(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  async function remove_user() {
    await removeUserAPI(selectedUserId)
      .then((res) => {
        setisDeleteModalOpen(false);
        toast.success("User successfully removed.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        get_all_users();
      })
      .catch((err) =>
        toast.error("Something went wrong, please try again.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        })
      );
  }

  async function update_user() {
    await updateUserAPI(orgId, account)
      .then((res) => {
        get_all_users();
        toast.success("User successfully updated.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      })
      .catch((err) =>
        toast.error("Something went wrong, please try again.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        })
      );
  }

  useEffect(() => {
    get_all_users();
  }, []);

  return (
    <Layout header="Settings">
      <div className="p-6">
        <div className="bg-white p-10 rounded-xl">
          {/* FORM */}
          <form
            className="mb-5"
            onSubmit={(e) => {
              e.preventDefault(); // Prevents default form submission behavior
              create_user(); // Calls the function only if all fields are filled due to HTML5 validation
            }}
          >
            <div className="flex space-x-5">
              <div
                onClick={() => setselectedTab("user")}
                className="cursor-pointer"
              >
                <h1
                  className={`${
                    selectedTab === "user" ? "text-black" : "text-gray-300"
                  } text-xl mb-1 hover:text-black`}
                >
                  Users
                </h1>
                <div
                  className={`${
                    selectedTab === "user" ? "bg-primary" : "bg-gray-300"
                  } h-1 w-14 transition-all duration-300`}
                />
              </div>

              <div
                onClick={() => setselectedTab("account")}
                className="cursor-pointer"
              >
                <h1
                  className={`${
                    selectedTab === "account" ? "text-black" : "text-gray-300"
                  } text-xl mb-1 hover:text-black`}
                >
                  Account
                </h1>
                <div
                  className={`${
                    selectedTab === "account" ? "bg-primary" : "bg-gray-300"
                  } h-1 w-18 transition-all duration-300`}
                />
              </div>
            </div>
            {selectedTab == "user" ? (
              <div className="flex space-x-10">
                <div className="grow my-10 grid grid-cols-2 gap-4 ">
                  <div>
                    <label className="text-sm text-[#5E6366]">Name</label>
                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                      <User className="h-5 w-5 my-auto ml-3" />
                      <input
                        className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                        placeholder="Enter name here..."
                      />
                    </div>
                  </div>

                  <div>
                    <label className="text-sm text-[#5E6366]">
                      Privilege (Admin/User)
                    </label>
                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                      <User className="h-5 w-5 my-auto ml-3" />
                      <select
                        onChange={(e) =>
                          setuser({ ...user, role: e.target.value })
                        }
                        className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                        required
                      >
                        <option value="" disabled selected>
                          select
                        </option>
                        <option value="admin">admin</option>
                        <option value="monitor">monitor</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <label className="text-sm text-[#5E6366]">Email</label>
                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                      <Email className="h-5 w-5 my-auto ml-3" />
                      <input
                        className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                        placeholder="Enter email here..."
                        onChange={(e) =>
                          setuser({ ...user, email: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="text-sm text-[#5E6366]">Password</label>
                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                      <input
                        className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                        placeholder="***********"
                        type="password"
                        onChange={(e) =>
                          setuser({ ...user, password: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="bg-primary mb-10 mt-auto text-white text-lg rounded-xl px-5 py-2"
                >
                  Create
                </button>
              </div>
            ) : null}
          </form>

          {/* TABLE */}
          {selectedTab == "user" ? (
            <Table
              data={users}
              setisDeleteModalOpen={setisDeleteModalOpen}
              setselectedUserId={setselectedUserId}
            />
          ) : (
            <div>
              <div className="flex space-x-10">
                <div className="grow my-10 grid grid-cols-2 gap-4 ">
                  <div>
                    <label className="text-sm text-[#5E6366]">
                      Tenant Name
                    </label>
                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                      <User className="h-5 w-5 my-auto ml-3" />
                      <input
                        className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                        placeholder="Enter tenant name here..."
                        onChange={(e) =>
                          setaccount({ ...account, name: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label className="text-sm text-[#5E6366]">Logo</label>
                    <div className="flex bg-[#EFF1F9] rounded-lg mt-2">
                      <Photo className="h-5 w-5 my-auto ml-3" />
                      <input
                        className="bg-[#EFF1F9] w-full py-3 pl-3 outline-none rounded-lg"
                        placeholder="Enter logo here..."
                        onChange={(e) =>
                          setaccount({ ...account, logo: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => update_user()}
                  className="bg-primary mb-10 mt-auto text-white text-lg rounded-xl px-5 py-2"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        title="user"
        open={isDeleteModalOpen}
        setOpen={setisDeleteModalOpen}
        func={() => remove_user()}
      />

      <ToastContainer />
    </Layout>
  );
}

function Table({ data, setisDeleteModalOpen, setselectedUserId }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg bg-white">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Email
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Role
            </th>

            <th
              scope="col"
              className=" text-right px-12 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, userIdx) => (
            <tr key={user.id}>
              <td
                className={classNames(
                  userIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell text-center"
                )}
              >
                <div className="font-medium text-gray-900">{user.email}</div>
              </td>

              <td
                className={classNames(
                  userIdx === 0 ? "" : "border-t border-gray-200",
                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell text-center"
                )}
              >
                {user.role}
              </td>

              <td
                className={classNames(
                  userIdx === 0 ? "" : "border-t border-gray-200",
                  "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:table-cell"
                )}
              >
                <div className="inline-flex w-fit border border-[#D5D5D5] rounded-lg bg-[#FAFBFD]">
                  {/* <button
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5  border-r border-[#D5D5D5]"
                  >
                    <Edit />
                  </button> */}

                  <button
                    type="button"
                    onClick={() => {
                      setselectedUserId(user._id);
                      setisDeleteModalOpen(true);
                    }}
                    className="inline-flex items-center px-2.5 py-1.5  "
                  >
                    <Trash />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
