import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import { ReactComponent as CategoryIco } from "../../assets/icons/Category.svg";
import { ReactComponent as SettingsIco } from "../../assets/icons/Setting.svg";
import { ReactComponent as ChatIco } from "../../assets/icons/Chat.svg";
import { ReactComponent as FolderIco } from "../../assets/icons/Folder.svg";
import { ReactComponent as ChartIco } from "../../assets/icons/Charts.svg";
import { ReactComponent as LogoutIco } from "../../assets/icons/Logout.svg";
import { ReactComponent as BillingIco } from "../../assets/icons/Billing.svg";

import { getSpecificUsersAPI } from "../../services/apis/Auth";

export default function Layout({ children, header }) {
  const location = useLocation();
  const navigate = useNavigate();
  const orgId = JSON.parse(localStorage.getItem("user"))["organization"];
  const [logo, setlogo] = useState("");

  const navigation = [
    { name: "Dashboard", href: "/dashboard", icon: ChartIco, current: true },
    { name: "Reports", href: "/reports", icon: FolderIco, current: true },
    { name: "Billing", href: "/billing", icon: BillingIco, current: true },
    {
      name: "Settings",
      href: "/settings",
      icon: SettingsIco,
      current: false,
    },
  ];

  async function get_specific_user() {
    await getSpecificUsersAPI(orgId)
      .then((res) => setlogo(res.data.data.logo))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    get_specific_user();
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div>
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-white px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center mt-10 mb-5">
            <img
              src={logo}
              className="object-cover"
              style={{ height: "7rem", width: "7rem" }} // Equivalent to 18
            />

            {/* <Logo className="m-auto h-18 w-18" /> */}
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          item.href == location.pathname
                            ? "bg-primary text-white"
                            : "text-custom-gray hover:bg-gray-50 hover:text-primary",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-light leading-6"
                        )}
                      >
                        <item.icon
                          aria-hidden="true"
                          className={classNames(
                            item.href == location.pathname
                              ? "text-white"
                              : "text-custom-gray group-hover:text-primary",
                            "h-5 w-5 shrink-0 my-auto"
                          )}
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="mt-auto">
                <a
                  href="/"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-light leading-6 text-custom-red hover:bg-gray-50 hover:text-custom-red"
                >
                  <LogoutIco
                    aria-hidden="true"
                    className="h-6 w-6 shrink-0 text-custom-red group-hover:text-custom-red"
                  />
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4  bg-white px-6">
          <div className="flex flex-1 gap-x-4 self-stretch  my-auto text-lg font-medium text-[#45464E]">
            {header}
          </div>
        </div>

        <main className="bg-[#F4F5FA] min-h-[93vh] p-6">{children}</main>
      </div>
    </div>
  );
}
